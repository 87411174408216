import React from 'react';

import { Layout } from '../components/Layout';

const WhatWeDo = () => (
  <Layout>
    <h1>What We Do</h1>
  </Layout>
);

export default WhatWeDo;
